
import { defineComponent } from "vue";
import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Calendar",
  components: {
    FullCalendar,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const router = useRouter();
    const todayDate = moment().startOf("day");
    const TODAY = todayDate.format("YYYY-MM-DD");

    const handleEventClick = (value) => {
      router.push({ name: "crmEdit", params: { id: value.event.id } });
    };

    const handleEventRemove = (value) => {
      // console.log("value", value);
    };

    return {
      calendarOptions: {
        locale: "es",
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },

        height: 800,
        contentHeight: 780,
        aspectRatio: 3,

        nowIndicator: true,
        now: TODAY + "T09:25:00",

        views: {
          dayGridMonth: { buttonText: "Mes" },
          timeGridWeek: { buttonText: "Semana" },
          timeGridDay: { buttonText: "Día" },
          listMonth: { buttonText: "Lista" },
        },

        initialView: "dayGridMonth",
        initialDate: TODAY,

        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        navLinks: true,

        events: props.data,
        eventClick: handleEventClick,
        eventRemove: handleEventRemove,
      } as CalendarOptions,
      currentEvents: [] as EventApi[],
    };
  },
});
