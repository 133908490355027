
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import OfficeSelect from "@/components/catalogs-select/OfficeSelect.vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import UsersCRMSelect from "@/components/catalogs-select/UsersCRMSelect.vue";
import ActionTypeSelect from "@/components/catalogs-select/ActionTypeSelect.vue";
import ActionStatusSelect from "@/components/catalogs-select/ActionStatusSelect.vue";
import ActionReasonSelect from "@/components/catalogs-select/ActionReasonSelect.vue";
import ActionResultSelect from "@/components/catalogs-select/ActionResultSelect.vue";
import Calendar from "@/components/crm/calendar/Calendar.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface IPagination {
  page: number;
  rowsPerPage: number;
  total: number;
}

export default defineComponent({
  name: "CRM",
  components: {
    OfficeSelect,
    EntitySelect,
    UsersCRMSelect,
    ActionTypeSelect,
    ActionStatusSelect,
    ActionReasonSelect,
    ActionResultSelect,
    Calendar,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const search = ref("");
    const dataList = ref([] as any);
    const filtered = ref([]);
    const office_id = ref([]);
    const entity_id = ref([]);
    const type_id = ref([]);
    const status_id = ref([]);
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    const reason_id = ref([]);
    const result_id = ref([]);
    const participant_id = ref([]);
    const responsible_id = ref([]);
    const description = ref("");
    const discharge_date = ref("");
    const start_time = ref("");
    const end_time = ref("");
    const next_action_date = ref("");
    const viewType = ref("calendar");
    const tableButtons = ref([]);
    const originalData = ref([]);
    const crmActions = computed(() => store.getters.CRMActions);
    const totalActions = computed(() => store.getters.TotalActions);
    const pagination = ref<IPagination>({
      page: 1,
      rowsPerPage: user.value.user_preferences.items_per_page,
      total: 0,
    });

    const header = ref([
      { column: t("ientity"), key: "entity_id" },
      { column: t("ioffice"), key: "office_id" },
      { column: t("iactiontype"), key: "type_id" },
      { column: t("iactionstatus"), key: "status_id" },
      { column: t("iactionreason"), key: "reason_id" },
      { column: t("iactionresult"), key: "result_id" },
      { column: t("idischargedate"), key: "discharge_date" },
      { column: t("istarttime"), key: "start_time" },
      { column: t("iendtime"), key: "end_time" },
      { column: t("inextaction"), key: "next_action_date" },
    ]);

    watch(
      () => crmActions.value,
      (first) => {
        originalData.value = first;
        dataList.value = createCalendarEvents(first);
        pagination.value.total = totalActions.value;
        filtered.value = createCalendarEvents(first);
      }
    );

    const deleteElement = ({ id, force }) => {
      ApiService.delete(`/api/actions/${id}?force_delete=${force}`).then(
        ({ data }) => {
          getItems();
        }
      );
    };

    const getItems = () => {
      store.commit("setLoading", false);
      store.dispatch("loadElements");
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("", [{ route: "/crm", label: "crm" }]);
      getItems();
    });

    const currentPageChange = (val) => {
      pagination.value.page = val;
      serverSearch();
    };

    const setItemsPerPage = (event) => {
      pagination.value.rowsPerPage =
        typeof event === "object" ? parseInt(event.target.value) : event;
      serverSearch();
    };

    const serverSearch = () => {
      store.commit("setLoadingStatus", true);
      ApiService.query(`/api/actions`, {
        params: {
          page: pagination.value.page,
          per_page: pagination.value.rowsPerPage,
          entity_id: entity_id.value,
          office_id: office_id.value,
          type_id: type_id.value,
          status_id: status_id.value,
          reason_id: reason_id.value,
          result_id: result_id.value,
          participant_id: participant_id.value,
          responsible_id: responsible_id.value,
          description: description.value,
          discharge_dates: discharge_date.value,
          next_action_dates: next_action_date.value,
        },
      }).then(({ data }) => {
        originalData.value = data.actions;
        dataList.value = createCalendarEvents(data.actions);
        pagination.value.total = data.actions.length;
        filtered.value = createCalendarEvents(data.actions);
        store.commit("setLoadingStatus", false);
      });
    };

    const onSearch = () => {
      filtered.value = dataList.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    const createElement = () => {
      store.commit("setLoadingc", false);
      router.push({ name: "crmCreate" });
    };

    const getColor = (type) => {
      switch (type) {
        case 1:
          return "#0068FB";
        case 2:
          return "#FF5733";
        case 3:
          return "#04AA77";
        case 4:
          return "#C70039";
        case 5:
          return "#7733FF";
        case 6:
          return "#FFA800";
      }
    };

    const isEventAllDay = (param1, param2) => {
      return param1 === "00:00:00" && param2 === "23:59:00";
    };

    const createCalendarEvents = (data) => {
      let events = [] as any;
      data.forEach((item) => {
        events.push({
          title: `${item.type.name}`,
          start: `${item.discharge_date}T${item.start_time}`,
          end: `${item.discharge_date}T${item.end_time}`,
          id: item.id,
          color: getColor(item.type_id),
          allDay: isEventAllDay(item.start_time, item.end_time),
        });
        if (item.next_action_date && item.next_action_type_id) {
          events.push({
            title: `${item.next_action_type.name}`,
            start: `${item.next_action_date}`,
            end: "",
            id: item.id,
            color: getColor(item.next_action_type_id),
            allDay: false,
          });
        }
      });
      return events;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 === "EDIT") {
        router.push({ name: "crmEdit", params: { id: param3 } });
      }
    };

    return {
      filtered,
      dataList,
      search,
      office_id,
      entity_id,
      type_id,
      status_id,
      reason_id,
      result_id,
      participant_id,
      responsible_id,
      description,
      discharge_date,
      start_time,
      end_time,
      next_action_date,
      viewType,
      header,
      tableButtons,
      pagination,
      deleteElement,
      onSearch,
      serverSearch,
      createElement,
      actionsButton,
      currentPageChange,
      setItemsPerPage,
    };
  },
});
