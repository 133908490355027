
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "UsersCRMSelect",
  props: {
    user_id: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:user_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const usersData = computed(() => store.getters.UsersCRM);
    const inputUser = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isUserRequired = (value) => {
      if (props.required && !value) {
        return t("ruser");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "user",
      isUserRequired
    );

    const selectUser = (query) => {
      if (query !== "") {
        inputUser.value.loading = true;
        setTimeout(() => {
          inputUser.value.loading = false;
          inputUser.value.options = inputUser.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputUser.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/users/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.users.forEach((o) => {
                store.commit("addOffice", o);
              });
              inputUser.value.list = usersData.value;
              inputUser.value.options = data.users;
            });
          }
        }, 200);
      } else {
        inputUser.value.options = usersData.value;
      }
    };

    watch(
      () => props.user_id,
      (first) => {
        if (first) {
          element_id.value = first;
        }
      }
    );

    watch(
      () => usersData.value,
      (first) => {
        inputUser.value.list = first;
        inputUser.value.options = first;
      }
    );

    onMounted(() => {
      inputUser.value.list = usersData.value;
      inputUser.value.options = usersData.value;
    });

    return {
      element_id,
      inputUser,
      errorMessage,
      selectUser,
    };
  },
};
